
	import SegmentHeader from "./SegmentsHeader.vue";
	import Vue from "vue";

	export default Vue.extend({
		name: "Segments",
		props: {},
		components: { SegmentHeader },
		data: () => ({}),
		created() {},
		async mounted() {},
		computed: {},
		methods: {},
	});
